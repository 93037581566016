.horizontal-slider,
.horizontal-slider .swiper-slide {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


.vertical-slider .swiper-pagination {
    position: absolute;
    top: 100px;
    left: 10px;
    z-index: 10;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20px;
    padding: 3px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    translate: 0 50%;
}

.swiper-pagination-bullet {
    background-color: #a6a5a5;
    opacity: 1;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.swiper-pagination-bullet-active {
    background-color: #ffffff;
    height: 13px;

}