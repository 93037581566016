@font-face {
    font-family: 'Helvetica';
    src: local('Helvetica'), url(../../fonts/Helvetica.ttf) format('truetype');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica-black';
    src: local('Helvetica-black'), url(../../fonts/Helvetica-Black.ttf) format('truetype');
    font-weight: 900;
    font-display: swap;
}

.dish {
    position: relative;
    width: 100%;
    /*height: calc(var(--vh, 1vh) * 100);*/
    height: 100svh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: white;
    margin-top: auto;
    min-height: 100svh;
}



.dish:before {
    content: '';
    position: absolute;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    max-height: 35%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}


.dish:after {
    content: '';
    position: absolute;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, #000000 34.2%, rgba(0, 0, 0, 0) 100%);
    height:100%;
   max-height: 50%;
    right: 0;
    bottom: 0;
    left: 0;
}


.dish .dish-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.dish-category {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    border-radius: 5px;
}

.dish-type-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.dish-type-name {
    font-family: 'Helvetica';
    font-size: .7em;
    text-transform: uppercase;
    font-weight: 100;
}

.dish-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.dish-video video,
.dish-video .react-player {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-controls {
    position: absolute;
    top: 100px;
    left: 20px;
    display: flex;
    gap: 10px;
    z-index: 1;
}

.video-controls button {
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 4px;
}

.video-react .video-react-control-bar,
.video-react-big-play-button {
    display: none !important;
}

@media only screen and (max-width: 500px) {

    .dish-item-left .dish-title,
    .dish-item-right .dish-price {
        font-size: 4vw;
    }

}