/* src/components/menu/CartaSection.css */
.menu-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
}

.menu-dish-item {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
}

.menu-dish-name {
    font-weight: bold;
}

.menu-dish-content p {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 300;
    color: #000;
}

.menu-dish-price {
    color: #000000;
    font-weight: bold;
    font-size: 17px;
}

.menu-alergenos {
    font-style: italic;
    color: #f00;
}

.dish-wrapper {
    border-bottom: 0;
    padding: 0;
}

.carta-header {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}

.carta-container {
    padding: 16px;
}

.close-button {
    cursor: pointer;
}