/* src/components/Preloader.css */
.preloader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    width: 100%;
    z-index: 1000;
    color: #fff;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #fff;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

p {
    margin-top: 0rem;
    font-size: 16px;
    color: #fff;
}

@media only screen and (max-width: 500px) {
    p {
        font-size: 3.7vw;
        margin-bottom: 3vw;
    }

}