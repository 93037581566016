.whatsapp-button {
    background-color: #25d366;
    border: none;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

