/* src/components/Home.css */
:root {
    --vh: 1vh;
    /* Valor por defecto */
}

html,
body,
#root,
.home {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(var(--vh, 1vh) * 100);
}