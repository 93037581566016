/* src/components/VerticalSlider.css */

.slider-container {
    position: relative;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    height: 100svh;
    overflow: hidden;
}

.dish-category {
    position: absolute;
    top: 20px;
    right: 10px;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, .0);
    padding: 5px 10px;
    border-radius: 5px;
    gap: 8px;
    z-index: 10;
    height: 50px;
    max-width: 56%;
}

.dish-type-icon {
    width: 40px;
    min-width: 40px;
    height: 40px;
    fill: #fff;
    margin-right: 0;
}

.dish-type-name {
    font-size: 1em;
    text-align: right;
}

.vertical-slider,
.vertical-slider .swiper-slide,
.vertical-slider,
.vertical-slider .swiper-slide {
    width: 100vw;
    height: 100svh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vertical-slider,
.vertical-slider .swiper-slide {
    width: 100vw;
    height: calc(100svh + 1px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-pagination-bullet:only-child {
    display: block !important;
}

@media only screen and (max-width: 500px) {
    .dish-type-name {
        font-size: 3.55vw;
    }

    .dish-type-icon {
        min-width: auto;
        width: 8vw;
        height: 8vw;
    }
}


@media only screen and (min-width: 500px) and (max-width: 1000px) {

    .dish-type-name {
        font-size: 20px;
    }

    .dish-type-icon {
        width: 45px;
        min-width: 45px;
        height: 45px;
    }
}

@media only screen and (min-width: 1000px) {

    .slider-container {
        max-width: 500px;
        margin: 0 auto;
    }

    .vertical-slider,
    .vertical-slider .swiper-slide,
    .vertical-slider,
    .vertical-slider .swiper-slide {
        max-width: 500px;
    }
}