.thumbs-slider-container {
    position: sticky;
    bottom: 0;
    width: 100%;
    /*background: linear-gradient(0deg, rgba(0, 0, 0, 1) 35%, rgba(0, 0, 0, 0) 90%);*/
    display: flex;
    justify-content: center;
    z-index: 90;
    padding: 2% 0 2% 0;
    /* Eliminar padding en los laterales */
}

.thumbs-slider-wrapper {
    width: 100%;
    /* Reducir el ancho del slider */
    height: 100%;
    position: relative;
}

.thumbs-slider {
    width: 90%;
    height: 100%;
}

.thumbs-slider .swiper-slide {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    margin-left: 35px !important;
    /* Aumenta el margen a 35px */
    margin-right: 35px !important;
    /* Aumenta el margen a 35px */
    width: calc(20% - 70px) !important;
    /* Ajusta el ancho en consecuencia */
}

.thumbs-slider .swiper-slide {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    margin-left: 0 !important;
    /* Aumenta el margen a 35px */
    margin-right: 0 !important;
    /* Aumenta el margen a 35px */
    width: calc(20% - 0px) !important;
    /* Ajusta el ancho en consecuencia */
}

.thumb {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    transition: color 0.3s ease;
    padding-left: 3vw;
    padding-right: 3vw;
}

.thumb-icon {
    width: 10vw;
    height: 10vw;
    fill: #fff;
    transition: filter 0.3s ease;
}

.thumb-name {
    margin-top: 5px;
    font-size: 2.8vw;
    line-height: 1;
    text-align: center;
    transition: color 0.3s ease;
}

.thumb-slide-active .thumb-icon {
    fill: #bcf5be;
}

.thumb-slide-active .thumb-name {
    color: #bcf5be;
}

.swiper {
    position: static !important;
}

/* Personaliza los botones de navegación predeterminados de Swiper */
.swiper-button-prev {
    left: 0;
    /* Mover la flecha hacia afuera del contenedor */
    z-index: 1000;
    /* Asegurar que tenga un z-index más alto */
}

.swiper-button-next {
    right: 0;
    /* Mover la flecha hacia afuera del contenedor */
    z-index: 1000;
    /* Asegurar que tenga un z-index más alto */
}

.swiper-button-next,
.swiper-button-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    color: #fff;
    /* Cambiar a un color brillante */
    font-size: 10px;
    /* Aumentar el tamaño del icono */
    position: absolute;
    top: 50%;
}

/* Ocultar los botones de navegación cuando están desactivados */
.swiper-button-disabled {
    display: none;
}

/* Personaliza el color y tamaño de los iconos */
.swiper-button-next::after,
.swiper-button-prev::after {
    font-size: 15px;
    font-weight: bolder;
    /* Aumentar el tamaño del icono */
    color: #fff;
    /* Cambiar a un color brillante */
}



@media only screen and (max-width: 500px) {
    .thumb-icon {
        width: 11vw;
        height: 11vw;
    }

    .thumb-name {
        font-size: 2.8vw;
        min-height: 5vw;
    }


}

@media only screen and (min-width: 500px) {

    .thumb-icon {
        width: 50px;
        height: 50px;
    }

    .thumb-name {
        font-size: 1em;
        min-height: 21px;
    }

    .thumb {
        padding-left: 10px;
        padding-right: 10px;
    }

}

@media only screen and (min-width: 1000px) {

    .thumb-icon {
        width: 42px;
        height: 42px;
    }

    .thumb-name {
        font-size: 0.75em;
    }
}