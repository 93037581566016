/* src/App.css */

html,
body,
#root {
    height: 100%;
    width: 100%;
    margin: 0;
    overflow: hidden;
}

.App {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}