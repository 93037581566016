.dish-content {
    position: absolute;
    bottom: 25svw;
    z-index: 1;
    display: flex;
    gap: 40px;
    align-items: top;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    padding-left: 4%;
    padding-right: 4%;
    z-index: 1;
}

.dish-item-left {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: top;
    max-width: 600px;
}

.dish-item-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    align-items: top;
    width: auto;
}

.dish-item-left .dish-title {
    font-family: 'Helvetica-black';
    font-size: 18px;
    text-align: left;
    margin: 0;
}

.dish-item-left .dish-title span {
    font-family: 'Helvetica' !important;
}

.dish-item-left .dish-description {
    font-family: 'Helvetica';
    font-size: 16px;
    text-align: left;
    margin: 0;
}

.dish-item-left .dish-content-detail {
    font-family: 'Helvetica';
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    margin: 0;
}

.dish-item-right .dish-price {
    font-family: 'Helvetica-black';
    font-size: 18px;
    margin: 0;
}

.dish-price span {
    font-family: 'Helvetica' !important;
}

.mute-button {
    background-color: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 0;
    font-size: 14px;
    margin-top: 15px;
    position: relative;
}

.mute-button svg {
    width: 32px;
    height: 32px;
}



.allergens {
    display: flex;
    place-self: flex-start;
    margin-top: 0px;
}

.allergen {
    display: flex;
}

.allergen-icon {
    width: 30px;
    height: 30px;
    margin-right: 8px;
}

.like-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    position: relative;
}

.like-icon {
    cursor: pointer;
    display: flex;
    transition: transform 0.2s ease-in-out;
    position: relative;
}

.like-icon.animate {
    animation: like-bounce 0.7s ease-in-out;
}

.heart-icon {
    transition: fill 0.2s;
}

.like-count {
    font-size: 1em;
}

.floating-heart {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2em;
    animation: float-up 0.7s ease-in-out;
}

.whatsapp-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    position: absolute;
    top: -12vw;
    right: 4%;
    margin: 0;
}

.whatsapp-icon {
    width: 32px;
    height: 32px;
    fill: white
}


@keyframes like-bounce {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }
}

@keyframes float-up {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(-50px);
    }
}

@media only screen and (max-width: 500px) {
    .allergen-icon {
        margin-right: 2vw;
        width: 7vw;
        height: 7vw;
    }

    .like-icon svg, .whatsapp-button svg {
        width: 7.5vw;
        height: 7.5vw;
    }

    .like-count {
        font-size: 4vw;
    }

    .like-container {
        margin-top: 2vw;
    }

    .mute-button {
        margin-top: 3vw;
    }

    .mute-button svg {
        width: 7vw;
        height: 7vw;
    }

}

@media only screen and (min-width: 500px) {
    .dish-content {
        bottom: 120px;
    }

    .dish-item-left .dish-title {
        font-size: 20px;
    }

    .dish-item-right .dish-price {
        font-size: 20px;
    }

    .sub {
        font-size: 18px;
    }

    .whatsapp-button {
        top: -50px;
    }

    .mute-button {
        margin-top: 15px;
    }
}

@media only screen and (min-width: 1000px) {
    .dish-content {
        bottom: 105px;
    }
}