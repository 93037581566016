/* src/components/Header.css */

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 45%;
    height: 100px;
    /*background: linear-gradient(180deg, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 0) 50%);*/
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: center;
    padding: 0 15px;
    z-index: 10;
    box-sizing: border-box;
    height: 90px;
}

.header .restaurant-image {
    width: 80px;
    height: auto;
}

.header .restaurant-image {
    height: auto;
    width: auto;
    max-height: 45px;
    min-height: 45px;
  }

  .restaurant-image {
    margin-top: 0 !important;
  }

.carta-icon {
    width: 35px;
    height: auto;
    max-height: 40px;
    margin-left: 25px;
    cursor: pointer;
    transition: filter 0.3s ease;
}

.carta-icon.active {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(0%);
}

@media only screen and (max-width: 500px) {
    .carta-icon {
        width: 6vw;
        height: auto;
        margin-left: 5.2vw;
    }


      .header .restaurant-image {
        height: auto;
        margin-top: 0;
        width: auto;
      }
}

@media only screen and (min-width: 500px) and (max-width: 1000px) {

    .header img.restaurant-image {
        width: 100px !important;
        height: auto;
    }

    .carta-icon {
        cursor: pointer;
        height: auto;
        transition: filter .3s ease;
        width: 32px;
      }
    
}


@media only screen and (min-width: 1000px) {

    .header {
        left: 50%;
        transform: translateX(-50%);
        max-width: 500px;
        width: 100%;
    }
}