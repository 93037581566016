.contenedor-menu {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.menu-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.menu-dish-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.menu-dish-name {
  font-weight: bold;
}

.menu-price {
  color: #000000;
  font-family: 'Helvetica-bold';
  font-weight: 400;
  font-size: 1.9em;
  text-align: center;
  display: inline-flex;
  align-items: center;
  width: auto;
  margin: 0 auto;
  margin-top: 15px;

}

.menu-price::before,
.menu-price::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000000 !important;
  display: inline-flex;
  width: 40px;

}


.menu-price::before {
  margin-right: .35em;
}

.menu-price::after {
  margin-left: .35em;
}

.menu-price::not(:empty)::before {
  margin-right: .55em !important;
}

.menu-price::not(:empty)::after {
  margin-left: .55em;
}

.menu-alergenos {
  font-style: italic;
  color: #000;
}

.dish-wrapper {
  border-bottom: 1px solid #ddd;
  padding: 8px 0;
}

.carta-header {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.carta-container {
  padding: 16px;
}

.close-button {
  cursor: pointer;
}