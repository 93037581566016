/* src/index.css */

body,
html,
#root {
    height: 100%;
    width: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #000;
    /* Fondo negro para el header transparente */
    color: white;
}

* {
    box-sizing: border-box;
}